import request from "@/utils/request";

// Lead
export function leadList(params) {
  return request({ url: `/leads/`, method: "get", params });
}

export function leadCreate(data) {
  return request({ url: `/leads/`, method: "post", data });
}

export function leadUpdate(data) {
  return request({ url: `/leads/${data.id}/`, method: "put", data });
}

export function leadDestroy(data) {
  return request({ url: `/leads/${data.id}/`, method: "delete", data });
}

export function leadChangeFollowUpPerson(data) {
  return request({ url: `/leads/change_follow_up_person/`, method: "post", data });
}

export function leadMarkAsPublic(data) {
  return request({ url: `/leads/${data.id}/mark_as_public/`, method: "post", data });
}

export function leadMarkAsPrivate(data) {
  return request({ url: `/leads/${data.id}/mark_as_private/`, method: "post", data });
}

// LeadFollowUpRecord
export function leadFollowUpRecordCreate(data) {
  return request({ url: `/lead_follow_up_records/`, method: "post", data });
}

export function leadFollowUpRecordUpdate(data) {
  return request({ url: `/lead_follow_up_records/${data.id}/`, method: "put", data });
}

export function leadFollowUpRecordDestroy(data) {
  return request({ url: `/lead_follow_up_records/${data.id}/`, method: "delete", data });
}

// LeadAttachment
export function leadAttachmentUpload(data) {
  return request({
    url: "/lead_attachments/",
    method: "post",
    data,
  });
}

// Application
export function applicationList(params) {
  return request({ url: `/applications/`, method: "get", params });
}

export function applicationCreate(data) {
  return request({ url: `/applications/`, method: "post", data });
}

export function applicationDestroy(data) {
  return request({ url: `/applications/${data.id}/`, method: "delete", data });
}

// Review
export function reviewList(params) {
  return request({ url: `/reviews/`, method: "get", params });
}

export function reviewAgree(data) {
  return request({ url: `/reviews/${data.id}/agree/`, method: "post", data });
}

export function reviewRefuse(data) {
  return request({ url: `/reviews/${data.id}/refuse/`, method: "post", data });
}

// Account
export function accountList(params) {
  return request({ url: `/accounts/`, method: "get", params });
}

export function accountCreate(data) {
  return request({ url: `/accounts/`, method: "post", data });
}

export function accountUpdate(data) {
  return request({ url: `/accounts/${data.id}/`, method: "put", data });
}

export function accountDestroy(data) {
  return request({ url: `/accounts/${data.id}/`, method: "delete", data });
}
